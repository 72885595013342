<!--
 * @Author: dongjia
 * @Date: 2021-08-12 17:38:00
 * @LastEditTime: 2021-12-20 14:12:04
 * @LastEditors: aleaner
 * @Description: 小组设置弹窗
 * @FilePath: \saas-admin-vue\src\modules\community-admin\components\group\GroupConfigDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog
    title="相关设置"
    :visible.sync="openDialog"
    width="500px"
    :show-close="true"
    class="dialog-vertical"
    @close="cancel('groupConfig')"
  >
    <el-form
      size="medium"
      :model="groupData"
      ref="groupConfig"
      label-width="125px"
      @submit.native.prevent
    >
      <el-form-item label="加入审核：" prop="require_check">
        <el-radio v-model="groupData.require_check" :label="0"
          >直接通过</el-radio
        >
        <el-radio v-model="groupData.require_check" :label="1"
          >需要审核</el-radio
        >
      </el-form-item>
      <el-form-item label="自动加入小组：" prop="member_auto_join">
        <el-radio v-model="groupData.member_auto_join" :label="1"
          >开启</el-radio
        >

        <el-radio v-model="groupData.member_auto_join" :label="0"
          >关闭</el-radio
        >
        <div class="form-tip">
          <span
            class="el-icon-info"
            style="font-size: 15px; color: #c0c4cc"
          ></span>
          <span>开启后会员可按组织架构加入对应小组，无需审批</span>
        </div>
      </el-form-item>
      <el-form-item
        label="选择架构："
        prop="auto_join_organizations"
        v-if="groupData.member_auto_join"
        :rules="[{ required: true, message: '请选择架构', trigger: 'blur' }]"
      >
        <el-cascader
          v-model="groupData.auto_join_organizations"
          :options="selectPositionOptions"
          :props="{
            checkStrictly: true,
            expandTrigger: 'hover',
            label: 'name',
            value: 'id',
            emitPath: false,
            multiple: true,
          }"
          :style="{ width: '100%' }"
          filterable
          @change="handleExportCatalogChange"
          ref="CascaderRef"
        >
        </el-cascader>
      </el-form-item>

      <el-form-item label="发帖限制：" prop="post_limit">
        <el-radio v-model="groupData.post_limit" :label="0">不限制</el-radio>
        <el-radio v-model="groupData.post_limit" :label="1">仅限成员</el-radio>
      </el-form-item>
      <el-form-item label="发帖审核：" prop="post_check">
        <el-radio v-model="groupData.post_check" :label="0">先发后审</el-radio>
        <el-radio v-model="groupData.post_check" :label="1">先审后发</el-radio>
      </el-form-item>
      <el-form-item label="黑名单限制：" prop="black_config">
        <el-checkbox-group v-model="groupData.black_config">
          <el-checkbox :label="1">无法发帖</el-checkbox>
          <el-checkbox :label="2">无法评论</el-checkbox>
          <el-checkbox :label="3">无法申请加入</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="黑名单自动移出：" prop="black_out_limit">
        <el-input v-model.number="groupData.black_out_limit">
          <i slot="suffix" style="margin-right: 10px">天</i>
        </el-input>
        <div class="form-tip">
          <span
            class="el-icon-info"
            style="font-size: 15px; color: #c0c4cc"
          ></span>
          <span>设置自动移出黑名单的天数，为空则不自动移出。</span>
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="openDialog = false">取消</el-button>
      <el-button type="primary" @click="submit('groupConfig')">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { saveConfig, getOrganizationsTree } from '../../api/group/group-list'
export default {
  model: {
    prop: 'open',
    event: 'closeDialog',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    Data: {
      type: Object,
    },
    id: {
      type: String,
      default: '',
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      optionsLoading: false,
      // 架构层级
      selectPositionOptions: [],
    }
  },
  methods: {
    // 关闭架构折叠面板
    handleExportCatalogChange() {
      this.$refs.CascaderRef.dropDownVisible = false
    },
    // 获取架构
    getPositionOptions() {
      getOrganizationsTree({ is_all: 1, is_show_none: 0 })
        .then((res) => {
          this.selectPositionOptions = res.data
        })
        .catch((err) => {})
    },
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const data = {}
          data.id = this.id
          data.config = this.groupData
          saveConfig(data)
            .then((res) => {
              this.$message.success(res.msg)
              this.updateList()
              this.$refs[formName].resetFields()
              this.openDialog = false
            })
            .catch((err) => {})
        } else {
          return false
        }
      })
    },
    // 取消
    cancel(formName) {
      this.openDialog = false
      this.$refs[formName].resetFields()
      console.log(this.groupData)
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('closeDialog', val)
      },
    },
    groupData: {
      get() {
        return this.Data
      },
      set(val) {
        console.log(val)
        // this.$emit('update: updateData', val)
      },
    },
  },
  mounted() {
    this.getPositionOptions()
  },
}
</script>

<style lang="scss" scoped>
.form-tip {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #606266;
  line-height: 12px;
  margin-top: 15px;
  span + span {
    margin-left: 4px;
  }
}
</style>
