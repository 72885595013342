<template>
  <div class="list-page">
    <el-button
      class="top-btn"
      type="primary"
      size="small"
      @click="handleAddGroup"
      >新增
    </el-button>
    <list-filter
      :filterForm="filterForm"
      :page="pageData.current_page"
      :uploadFilter="ok"
    />
    <list-tabs
      v-model="filterForm.tab"
      :tabs="tabArr"
      :isLoading="loading"
      @tab-click="handleClick"
    >
    </list-tabs>
    <div class="list-content" v-loading="loading">
      <div class="list-btn" v-if="filterForm.tab === 'normal'">
        <!-- <el-button type="warning" size="small" @click="handleJoin">加入组织</el-button> -->
        <el-button type="danger" size="small" @click="handleDel"
          >删除</el-button
        >
      </div>
      <div class="list-btn" v-if="filterForm.tab === 'deleted'">
        <el-button type="success" size="small" @click="handleReduct"
          >还原</el-button
        >
        <el-button type="danger" size="small" @click="handleDel"
          >删除</el-button
        >
      </div>
      <el-table
        :data="tbody"
        class="thead-light"
        stripe
        style="width: 100%"
        @selection-change="handleSelectionChange"
        @sort-change="sortChange"
      >
        <!-- 勾选-->
        <el-table-column fixed type="selection" width="55"></el-table-column>
        <!-- 操作 -->
        <el-table-column
          label="操作"
          :width="filterForm.tab == 'normal' ? '150' : '120'"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button-group v-if="filterForm.tab == 'deleted'">
              <el-button size="small" @click="ReductRow(scope.row)" type="text">
                还原
              </el-button>
              <el-button size="small" @click="deleteRow(scope.row)" type="text"
                >删除</el-button
              >
            </el-button-group>
            <div v-if="filterForm.tab == 'normal'">
              <el-button
                class="list-btn-style"
                size="small"
                @click="configRow(scope.row)"
                type="text"
              >
                设置
              </el-button>
              <el-button
                class="list-btn-style"
                size="small"
                @click="editRow(scope.row)"
                type="text"
              >
                编辑
              </el-button>
              <el-divider direction="vertical" />
              <el-dropdown @command="handleClickRow($event, scope.row)">
                <span class="el-dropdown-link">更多</span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="(item, index) in moreHandleList"
                    :key="index"
                    :command="item.command"
                    >{{ item.name }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column
            show-overflow-tooltip
            :prop="th.prop"
            :label="th.label"
            :min-width="th.minWidth || ''"
            :key="index"
            :sortable="th.sortable"
          >
            <template slot-scope="scope">
              <el-checkbox
                v-if="th.prop === 'is_recommend' || th.prop === 'is_show'"
                v-model="tbody[scope.$index][th.prop]"
                :true-label="1"
                :false-label="0"
                @change="changeStatus(scope.row, th.prop)"
              >
              </el-checkbox>
              <el-image
                v-else-if="th.prop === 'icon'"
                class="list-icon cursor-pointer"
                :src="fixImageUrl(scope.row[th.prop])"
                @click="$previewImage([scope.row[th.prop]])"
              >
              </el-image>
              <span v-else-if="th.prop === 'parent' && scope.row[th.prop]">{{
                scope.row[th.prop].name | placeholder
              }}</span>
              <!-- 各数量点击跳转页面 -->
              <a
                class="link-href"
                v-else-if="
                  [
                    'members_count',
                    'activities_count',
                    'topics_count',
                    'posts_count',
                  ].includes(th.prop)
                "
                @click="jumpTo(scope.row, th.prop)"
                >{{ scope.row[th.prop] | placeholder }}</a
              >
              <!-- 排序 -->
              <PopoverInputEdit
                v-else-if="th.prop === 'sort'"
                v-model="scope.row.sort"
                input-type="number"
                :validator="validator"
                @confirm="changeStatus(scope.row, th.prop)"
              >
              </PopoverInputEdit>
              <!-- 普通 -->
              <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <Pagination
      :total="pageData.total"
      :page="pageData.current_page"
      :change="changPage"
    />
    <GroupConfigDialog
      v-model="openGroupConfigDialog"
      :Data="groupConfig"
      :id="current_id"
      :updateList="refreshList"
    ></GroupConfigDialog>
  </div>
</template>

<script>
import listFilter from '../../components/group/ListFilter'
import PopoverInputEdit from '@/base/components/Popover/PopoverInputEdit'
import Pagination from '@/base/components/Default/Pagination'
import GroupConfigDialog from '../../components/group/GroupConfigDialog'
import {
  groupList,
  updateSort,
  updateShow,
  updateRecommend,
  softDelete,
  Delete,
  putBack,
} from '../../api/group/group-list'
export default {
  data() {
    return {
      tabArr: [
        { label: '全部', name: 'normal' },
        { label: '回收站', name: 'deleted' },
      ],
      loading: false, //加载
      thead: [
        { label: '排序', prop: 'sort' },
        // { label: "ID", prop: "id" },
        { label: '图标', prop: 'icon', minWidth: 100 },
        { label: '名称', prop: 'name', minWidth: 110 },
        // { label: "分类", prop: "category_name", minWidth: 110 },
        {
          label: '成员数',
          prop: 'members_count',
          minWidth: 90,
          sortable: true,
        },
        {
          label: '活动数',
          prop: 'activities_count',
          minWidth: 90,
          sortable: true,
        },
        {
          label: '话题数',
          prop: 'topics_count',
          minWidth: 100,
          sortable: true,
        },
        {
          label: '帖子数',
          prop: 'posts_count',
          minWidth: 100,
          sortable: true,
        },
        {
          label: '编辑时间',
          prop: 'update_time',
          minWidth: 160,
          sortable: true,
        },
        { label: '推荐', prop: 'is_recommend' },
        // { label: "显示", prop: "is_show" },
      ],
      //筛选对象
      filterForm: {
        update_start_time: -1, //开始时间
        update_end_time: -1, //结束时间
        category_id: -1,
        name: '', //内容名称
        pid: -2, //所属组织
        tab: 'normal', //当前筛选tab
        page_size: 15, //每页多少条数据
      },
      // 筛选缓存
      filters: {
        normal: null,
        deleted: null,
      },
      moreHandleList: [
        { name: '成员管理', command: 'memberAdmin' },
        { name: '活动管理', command: 'groupActivityList' },
        { name: '话题管理', command: 'groupTopicList' },
        { name: '帖子管理', command: 'groupPostList' },
        // { name: "推广", command: "extension" },
        { name: '删除', command: 'deleteRow' },
      ],
      pageData: {
        page_size: 15,
        tab: 'normal',
      },
      tbody: [],
      selectArr: [], //勾选的数组id
      /* 行内编辑数字验证 */
      validator: {
        type: 'integer',
        min: 0,
        message: '最小不能低于0的整数',
        trigger: 'blur',
      },
      groupConfig: {},
      current_id: '',
      openGroupConfigDialog: false, // 开启设置弹窗
    }
  },
  methods: {
    // 获取品牌列表
    getGroupList(pageData) {
      this.tbody = []
      this.pageData = {}
      this.loading = true
      groupList(pageData)
        .then((res) => {
          const { data } = res
          this.tbody = data.data
          this.pageData = data
          // this.filters[data.tab] = { ...this.filterForm };
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 获取上级组织列表
    // getPidList() {
    //   const data = {};
    //   data.is_show_no_parent = data.is_show_all_group = 1;
    //   PidList(data)
    //     .then((res) => {
    //       this.pid_list = res.data;
    //     })
    //     .catch((err) => {});
    // },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = []
      } else {
        this.selectArr = []
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id)
        })
      }
    },
    // 新增品牌
    handleAddGroup() {
      this.$router.push({
        name: 'addGroup',
        params: { id: 0 },
      })
    },
    // 批量删除
    handleDel() {
      if (this.selectArr.length) {
        if (this.filterForm.tab === 'normal') {
          this.$msgbox
            .confirm('确定要将选中数据移入回收站吗？', '提示', {
              type: 'info',
            })
            .then((res) => {
              this.handleDelete({ id: this.selectArr })
            })
            .catch((err) => {})
        } else {
          this.$msgbox
            .confirm('确定要将选中数据永久删除吗？', '提示', {
              type: 'error',
            })
            .then((res) => {
              this.handleDelete({ id: this.selectArr })
            })
            .catch((err) => {})
        }
      } else {
        this.$message.info('请选择数据项')
      }
    },
    // 点击列表删除
    deleteRow(row) {
      if (this.filterForm.tab === 'normal') {
        this.$msgbox
          .confirm('确定要将此数据移入回收站吗？', '提示', {
            type: 'info',
          })
          .then((res) => {
            this.handleDelete({ id: row.id })
          })
          .catch((err) => {})
      } else {
        this.$msgbox
          .confirm('确定要将此数据永久删除吗？', '提示', {
            type: 'error',
          })
          .then((res) => {
            this.handleDelete({ id: row.id })
          })
          .catch((err) => {})
      }
    },
    // 数据删除
    handleDelete(id) {
      this.loading = true
      if (this.filterForm.tab === 'normal') {
        softDelete(id)
          .then((res) => {
            this.$message.success(res.msg)
            this.refreshList()
          })
          .catch((err) => {
            this.loading = false
          })
      } else {
        Delete(id)
          .then((res) => {
            this.$message.success(res.msg)
            this.refreshList()
          })
          .catch((err) => {
            this.loading = false
          })
      }
    },
    // 批量还原数据
    handleReduct() {
      if (this.selectArr.length) {
        this.$msgbox
          .confirm('确定要将选中数据还原吗？', '提示', {
            type: 'info',
          })
          .then((res) => {
            this.Reduct({ id: this.selectArr })
          })
          .catch((err) => {})
      } else {
        this.$message.info('请选择数据项')
      }
    },
    // 点击列表还原
    ReductRow(row) {
      this.$msgbox
        .confirm('确定要将此数据还原吗？', '提示', {
          type: 'info',
        })
        .then((res) => {
          this.Reduct({ id: row.id })
        })
        .catch((err) => {})
    },
    // 还原数据
    Reduct(id) {
      this.loading = true
      putBack(id)
        .then((res) => {
          this.$message.success(res.msg)
          this.refreshList()
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 点击编辑
    editRow(row) {
      this.$router.push({
        name: 'groupDetail',
        params: { id: row.id },
      })
    },
    // 点击设置
    configRow(row) {
      this.groupConfig = JSON.parse(JSON.stringify(row.config))
      this.current_id = row.id
      this.openGroupConfigDialog = true
    },
    // 点击下拉菜单
    handleClickRow(value, row) {
      if (value === 'deleteRow') {
        this[value](row)
      } else {
        this.$router.push({
          name: value,
          params: { id: row.id },
        })
      }
    },
    // 跳转详情管理页面
    jumpTo(row, key) {
      switch (key) {
        case 'members_count':
          this.$router.push({
            name: 'memberAdmin',
            params: { id: row.id },
          })
          break
        case 'activities_count':
          this.$router.push({
            name: 'groupActivityList',
            params: { id: row.id },
          })
          break
        case 'topics_count':
          this.$router.push({
            name: 'groupTopicList',
            params: { id: row.id },
          })
          break
        case 'posts_count':
          this.$router.push({
            name: 'groupPostList',
            params: { id: row.id },
          })
          break
        default:
          break
      }
    },
    // 切换tab栏
    handleClick(e) {
      console.log(e.name)
      if (this.filters[e.name]) {
        this.filterForm = this.filters[e.name]
      } else {
        this.filterForm = {
          update_start_time: -1, //开始时间
          update_end_time: -1, //结束时间
          category_id: -1,
          name: '', //内容名称
          pid: -2, //所属组织
          tab: e.name, //当前筛选tab
          page_size: this.pageData.per_page, //每页数据量
        }
      }
      this.getGroupList(this.filterForm)
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === 'ascending'
        ? 0
        : 1
      this.filterForm.order_by = column.prop
      this.getGroupList(this.filterForm)
    },
    // 筛选回调
    ok(e) {
      const pageData = {}
      this.filterForm = { ...this.filterForm, ...e, page: 1 }
      this.getGroupList({ ...this.filterForm, ...pageData })
    },
    // 刷新列表
    refreshList() {
      this.getGroupList({
        page: this.pageData.current_page,
        ...this.filterForm,
      })
    },
    // 修改列表相关状态
    changeStatus(e, key) {
      const data = {}
      data.id = e.id
      data[key] = e[key]
      this.loading = true
      if (key == 'is_recommend') {
        // 更新推荐状态
        updateRecommend(data)
          .then((res) => {
            this.$message.success(res.msg)
            this.refreshList()
          })
          .catch(() => {
            this.refreshList()
          })
      } else if (key === 'is_show') {
        // 更新显示状态
        updateShow(data)
          .then((res) => {
            this.$message.success(res.msg)
            this.refreshList()
          })
          .catch(() => {
            this.refreshList()
          })
      } else {
        // 更新排序状态
        updateSort(data)
          .then((res) => {
            this.$message.success(res.msg)
            this.refreshList()
          })
          .catch(() => {
            this.refreshList()
          })
      }
    },
    // 分页查询
    changPage(e) {
      this.filterForm.page_size = e.page_size
      const pageData = { ...this.filterForm, ...e }
      this.getGroupList(pageData)
    },
  },
  created() {
    // this.getPidList(); //获取上级组织列表
    this.getGroupList(this.pageData) //获取列表数据
  },
  components: {
    listFilter,
    Pagination,
    PopoverInputEdit,
    GroupConfigDialog,
  },
}
</script>

<style lang="scss" scoped>
.container {
  .body {
    .err-box {
      ::v-deep.el-input__inner {
        color: red;
      }
    }
  }
}
.list-icon {
  width: 60px;
  height: 60px;
}
.list-btn-style {
  padding: 0;
}
.link-href {
  cursor: pointer;
  text-decoration: underline;
  color: #3576ff;
}
</style>
