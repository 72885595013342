<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :toggleCollapseEnable="false" :onExport="onExport">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="70px" size="medium"
      class="filter-from">
      <div class="filter-item">
        <DatePeriodPicker label="编辑时间：" start-prop="update_start_time"
          :start-time.sync="form.update_start_time" end-prop="update_end_time"
          :end-time.sync="form.update_end_time" />
      </div>
      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="小组名称：" prop="name">
          <el-input v-model="form.name" placeholder="请输入小组名称"></el-input>
        </el-form-item>
<!--        <el-form-item label="小组分类：" prop="category_id">-->
<!--          <el-select v-model="form.category_id" placeholder="请选择小组分类">-->
<!--            <el-option v-for="item in categoryOptions" :key="item.id" :label="item.name" :value="item.id">-->
<!--            </el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { categoryOptions } from "../../api/category-list";
import { groupsExport } from "../../api/group/group-list";
export default {
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      rules: {},
      categoryOptions: [],
    };
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
    // 导出
    onExport() {
      const postData = { ...this.form, page: this.page };
      return groupsExport(postData)
        .then()
        .catch(() => {});
    },
  },
  created() {
    categoryOptions()
      .then((res) => {
        this.categoryOptions = [{ id: -1, name: "全部" }, ...res.data];
      })
      .catch((err) => {});
  },
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {},
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>
